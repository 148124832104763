var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('DataViewWrapper', {
    ref: "dataView",
    attrs: {
      "filters": _vm.filters,
      "table-columns": _vm.tableColumns,
      "model": _vm.model,
      "read-only": true
    },
    scopedSlots: _vm._u([{
      key: "alertMessage",
      fn: function fn() {
        return [_c('div', {
          staticClass: "mt-2 mb-2 ml-3",
          staticStyle: {
            "width": "fit-content"
          }
        }, [_c('b-alert', {
          staticClass: "px-4 py-2 m-0",
          attrs: {
            "show": "",
            "variant": "info"
          }
        }, [_vm._v(" Report displays bottlings starting from 2024-01-01 mapped to Market Product ")])], 1)];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }