<template>
  <div class="animated fadeIn">
    <DataViewWrapper
      ref="dataView"
      :filters="filters"
      :table-columns="tableColumns"
      :model="model"
      :read-only="true"
    >
      <template #alertMessage>
        <div class="mt-2 mb-2 ml-3" style="width: fit-content;">
          <b-alert show variant="info" class="px-4 py-2 m-0">
            Report displays bottlings starting from 2024-01-01 mapped to Market Product
          </b-alert>
        </div>
      </template>
    </DataViewWrapper>
  </div>
</template>

<script>
import models from '@/models'

export default {
  name: 'BatchRemains',
  components: {},
  data: function () {
    return {
      model: models.warehouse.batchRemains,
      filters: [
        'Finished Product',
        'Product Name Full',
        
        'Bottling Date',
        'Last Sold Date'
      ],
      tableColumns: [
        'ID',
        'Finished Product',
        'Product Name Full',
        'Batch Number',
        'Bottling Date',
        'Last Sold Date',
        'Bottled',
        'Sold',
        'In Stock',
        'Difference',
        'Variance',
        'Duplicated Batch'
      ]
    }
  },
  computed: {},
  created () {},
  mounted () {},
  methods: {},
  watch: {}
}
</script>

<style></style>
